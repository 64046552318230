.ui.inverted.dimmer .ui.loader:before {
  border-color: #6600ff;
}

.ui.inverted.dimmer .ui.loader:after {
  border-color: #ffffff transparent transparent;
}

.ui.massive.loader:after, .ui.massive.loader:before {
  width: 6rem !important;
  height: 6rem !important;
}

.ui.dimmer {
  position: fixed !important;
}
.redcrossIcon{
  position: absolute;
  z-index:1001;
  right: 20px;
  top:20px;
  height: 40px;
  width: 40px;
  background: transparent url("../../Images/cross.png") no-repeat center;
  opacity: 1;

}