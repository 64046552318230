.Value-box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2% 5%;
  font-family: "MONT";
}
.popup-input {
  border: 2px solid #2b7fda;
  border-radius: 4px;
  margin-right: 10px;
}
input.popup-input {
  width: 98%;
}
.value {
  font-family: "MONT";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
}

.actions-value-box {
  width: 20%;
}

.actions-value {
  display: flex;
  flex-direction: row;
  flex-basis: fit-content;
  justify-content: space-between;
  align-content: center;
}
.value-edit,
.value-cancel,
.value-delete,
.value-update {
  font-family: "MONT";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  margin: 0;
}

.value-edit,
.value-update {
  color: #2b7fda;
}
.value-edit,
.value-cancel,
.value-delete,
.value-update:hover {
  cursor: pointer;
}
p.value {
  padding-right: 10px;
}
.fill-width{
  width: 80%;
}