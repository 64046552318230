@font-face {
  font-family: "SEGOEUI";
  font-style: normal;
  font-weight: 500;
  src: local("SEGOEUI"), url(../fonts/SEGOEUI.TTF) format("truetype");
}
@font-face {
  font-family: "SEGOEUIB";
  font-style: Bold;
  font-weight: 600;
  src: local("SEGOEUIB"), url(../fonts/SEGOEUIB.TTF) format("truetype");
}

@font-face {
  font-family: "SEGOEUII";
  font-style: italic;
  font-weight: 400;
  src: local("SEGOEUII"), url(../fonts/SEGOEUII.TTF) format("truetype");
}
@font-face {
  font-family: "SEGOEUIL";
  font-style: normal;
  font-weight: 400;
  src: local("SEGOEUIL"), url(../fonts/SEGOEUIL.TTF) format("truetype");
}
@font-face {
  font-family: "SEGOEUISL";
  font-style: bold;
  font-weight: 600;
  src: local("SEGOEUISL"), url(../fonts/SEGOEUISL.TTF) format("truetype");
}
@font-face {
  font-family: "SEGOEUIZ";
  font-style: italic;
  font-weight: 500;
  src: local("SEGOEUIZ"), url(../fonts/SEGOEUIZ.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUIBL";
  font-style: normal;
  font-weight: 500;
  src: local("SEGUIBL"), url(../fonts/SEGUIBL.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUIBLI";
  font-style: italic;
  font-weight: 500;
  src: local("SEGUIBLI"), url(../fonts/SEGUIBLI.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUILI";
  font-style: normal;
  font-weight: 400;
  src: local("SEGUILI"), url(../fonts/SEGUILI.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUISB";
  font-style: normal;
  font-weight: 400;
  src: local("SEGUISB-SemiBoldItalic"),
    url(../fonts/SEGUISB.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUISBI";
  font-style: bold;
  font-weight: 600;
  src: local("SEGUISBI"), url(../fonts/SEGUISBI.TTF) format("truetype");
}
@font-face {
  font-family: "SEGUISLI";
  font-style: italic;
  font-weight: 400;
  src: local("SEGUISLI-Italic"), url(../fonts/SEGUISLI.TTF) format("truetype");
}
@font-face {
  font-family: "MONT";
  font-style: normal;
  font-weight: 400;
  src: local("MONT"), url(../fonts/MONT.TTF) format("truetype");
}
