.fieldlist-container {
  width: 100%;
  min-height: 80vh;
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.heading-dropdown {
  width: 100%;
  height: 30px;
  margin-left: 5%;
  font-family: "MONT";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 22px;
  color: #03b7de;
}
.line-dropdown {
  margin-right: auto;
  margin-left: auto;
  width: 90%;
  height: 0px;
  top: 90px;

  border: 1px solid #858585;
}

.select-option {
  margin-bottom: 40px;
}
.ui.fluid.search.selection.dropdown.form-conditionals-dropdown {
  width: 200px;
  left: -139px;
}
.ui.fluid.search.selection.dropdown.form-conditionals-dropdown1 {
  width: 250px;
  left: -16px;
}
.select-dropdown {
  font-family: "MONT";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  width: 211px;
  height: 27px;
  left: 66px;
  top: 173px;
  color: #2b7fda;
}

h3.select-dropdown {
  width: 20pc;
}

.field-area {
  display: block;
}
.field-dropdown-name {
  font-family: "MONT";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;

  color: #2b7fda;
}

.field-box-dropdown {
  width: 50vw;
  height: auto;
  left: 100px;
  top: 0px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
  align-content: center;
  padding-bottom: 20px;
}
.flex-dropdown {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 5%;
  text-transform: uppercase;
  font-family: "MONT";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #616161;
 
}

.line1-dropdown {
  top: 18px;
  margin-right: auto;
  margin-left: auto;
  width: 95%;
  height: 0px;

  border: 1px solid #858585;
}
.value-dropdown  {
  
  padding-top: 35px;
  color: #616161;
}
.actions-dropdown {
  margin-left: 54px;
  padding-top: 35px;
  color: #616161;
}
.dropdown-whole {
  display: flex;
  flex-direction: row;
  margin-left: 5%;
  margin-bottom: 20px;
  justify-content: space-between;
  width: 90%;
  margin-top: 50px;
}
