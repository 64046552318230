@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');

.montserrat-font {
  font-family: "Montserrat", sans-serif;
  font-optical-sizing: auto;
  font-weight: 100;
  font-style: normal;
}

/* Platform Admin Header Styles */

.platform-admin-header {
  width: 100%;
  padding: 10px 40px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: left;
  box-shadow: 0px 5px 10px #CCC;
  position: relative;
  z-index: 10;
}

.platform-admin-header img {
  height: 100%;
  cursor: pointer;
}

.platform-admin-header .user-details {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.platform-admin-header .user-details .user-name {
  font-size: 14px;
  margin: 0 10px 0 0;
}

.platform-admin-header .user-details .user-img {
  width:40px;
  height: 40px;
  border-radius: 20px;
  background: linear-gradient(149deg, #2B7FDA 14.69%, #102234 78.44%);
  cursor: pointer;
}

/* Platform Admin Sidebar Styles */
.platform-admin-sidebar {
  width: 220px;
  margin: 30px 40px;
  height: 95%;
  border-radius: 5px;
  background: #FFF;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
  z-index: 5;
}

.platform-admin-sidebar ul li {
  width: 100%;
}

.platform-admin-sidebar ul li.ant-menu-submenu-selected {
  background: linear-gradient(90deg, rgba(43,127,218,1) 0%, rgba(25,59,104,1) 100%, rgba(0,212,255,1) 100%) !important;
}

.platform-admin-sidebar ul li.ant-menu-submenu-selected .ant-menu-submenu-title {
  color: #FFFFFF !important;
}

.platform-admin-sidebar ul li.ant-menu-submenu-selected .ant-menu-item-selected {
  background-color: #F3F8FF !important;
}


/* Platform Admin Onboarding Page */

.onboarding-form {
  background: linear-gradient(90deg, rgba(43,127,218,1) 0%, rgba(25,59,104,1) 100%, rgba(0,212,255,1) 100%);
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  color: #FFFFFF;
  padding: 10px 10px 30px 10px;
  flex-wrap: wrap;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  position: relative;
}

.onboarding-form .form-loader-overlay {
  position: absolute;
  background-color: rgba(255, 255, 255, 0.8);
  width: 100%;
  height: 104%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
}

.onboarding-form .form-content-left {
  width: 60%;
  height: 100%;
  padding: 10px;
}

.onboarding-form .form-content-left .form-title {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #FFF;
  padding-bottom: 5px;
  width: fit-content;
}

.onboarding-form .form-content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;
}

.onboarding-form .form-content .form-field {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 20px;
}

.onboarding-form .form-content .form-field .form-label {
  width: 40%;
  margin: 0;
}

.onboarding-form .form-content .form-field .form-input {
  height: 35px;
  width: 100%;
}

.onboarding-form .form-content .form-field .color-picker {
  /* width: 100%; */
  display: flex;
  justify-content: flex-start;
  border-radius: 3px;
  height: 35px;
}

.onboarding-form .form-content .form-field.error-field .form-input {
  border: 2px solid #F00 !important;
}

.onboarding-form .form-content .form-field.error-field .color-picker {
  border: 2px solid #F00 !important;
}

.onboarding-form .form-content-right {
  width: 40%;
  height: 100%;
  padding: 50px 10px 10px 10px;
}

.onboarding-form .form-content-right .logo-uploader {
  color: #FFF;
  width: 100%;
}

.onboarding-form .form-content-right .logo-uploader .ant-upload-select {
  width: 100%;
  height: 125px;
  background-color: transparent;
  color: #FFF;
}

.onboarding-form .form-content-right .error-field .logo-uploader .ant-upload-select {
  border-color: #F00;
  color: #F00;
}

.onboarding-form .form-content .form-field.error-field .ant-select-selector {
  border: 2px solid #F00 !important;
}

.onboarding-form .button-container {
  margin-top: -30px;
  color: black;
}

.onboarding-form .button-container button {
  margin: 10px 10px;
  border-radius: 5px;
  background-color: #DADADA;
}

.clients-list {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  /* margin: 30px 0; */
  flex-wrap: wrap;
  position: relative;
}

.clients-list .clients-list-loading-overlay {
  position: absolute;
  width: 100%;
  margin-top: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.clients-list .clients-list-loading-overlay .clients-list-loader {
  font-size: 50px;
}

p.section-title {
  font-size: 16px;
  text-transform: uppercase;
  border-bottom: 1px solid #2B7FDA;
  padding-top: 10px;
  width: fit-content;
  color: black;
}

.clients-list .search-box {
  width: 50%;
}

.clients-list .search-box input {
  border-radius: 10px;
}

.clients-list .search-box button {
  border-top-right-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.clients-list .client-card {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 48%;
  height: 100px;
  border: 10px;
  background-color: #FFF;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 0 10px;
  margin-bottom: 20px;
  position: relative;
}

.clients-list .client-card .card-overlay {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
  margin-left: -10px;
}

.clients-list .client-card .company-logo {
  width: 30%;
}

.clients-list .client-card .company-details {
  display: flex;
  flex-direction: column;
  color: #000;
  font-size: 12px;
  width: 50%;
  overflow: hidden;
}

.clients-list .client-card .company-details p {
  margin: 0;
  width: 100%;
  text-transform: uppercase;
}

.clients-list .client-card .theme-color {
  width: 20px;
  height: 20px;
  background-color: #F00;
  box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.25);
}

.clients-list .client-card .edit-button {
  border: none;
  color: #2B7FDA;
}

.clients-list .client-card .delete-button {
  border: none;
  color: black;
  background-color: transparent;
}

.superuser-table{
  width:100%;
}

.next-button {
  background-color: #fff;
  color: #000;
  padding-bottom: 10px;
}

.form-content-right.color-container {
  width: 50% !important;
  padding: 50px 10px 10px 10px !important;
}

.form-content-left.color-container {
  width: 50% !important;
}

.form-content-right.color-container.onboarding {
  padding: 30px 10px 10px 10px !important;
}

.form-content-right.onboarding {
  padding: 30px 10px 10px 10px !important;
}

.onboarding-form.color-container {
  padding-bottom: 5px;
}

.onboarding-form.color-container {
  box-shadow: none;
  background: none;
}

.ant-select.ant-select-outlined.form-input.css-dev-only-do-not-override-1okl62o.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

.ant-select.ant-select-outlined.form-input.css-1okl62o.ant-select-single.ant-select-show-arrow {
  width: 100%;
}

.error-message-format{
  width: 100%;
}

/* button.ant-btn.css-dev-only-do-not-override-1okl62o.ant-btn-primary.ant-btn-icon-only.edit-button{
  background: transparent;
  color: black;
} */

