@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;700;800;900&family=Raleway&display=swap');
html,
body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: "SEGOEUI";
}
::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* make scrollbar transparent */
}

/* Container */

.container {
  width: 100%;
  float: left;
}
/* Header Section */

.header {
  top: 0;
  background: #102234; 
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.logo {
  min-width: 288px;
  width: 15%;
  height: 58px;
  opacity: 1;
  background-size: contain;
}
.logo-login{
  position: absolute;
  min-width: 288px;
  width: 15%;
  height: 10%;
  opacity: 1;
  background-size: contain !important;
  left: 3%;
  top: 3%;
}
.logo > img , .logo-login > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.add-user .container{
  background-color: #102234;
}

/* Style the navigation bar */
.menu-items{
  width: 85%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.merchant-menu-items{
  justify-content: end;
}
.merchant-navbar{
  z-index: 1 !important;
}
.menu {
  width: 100%; 
  margin: 0px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
ul {
  list-style-type: none;
  margin: 0 !important;
  padding: 0;
  overflow: hidden;
}

li {
  float: left;
}

.menu ul li {
  margin: 5px 0;
}

li a{
  display: inline-block;
  background-color: transparent;
  box-shadow: 0 0 0 1px #ffffff inset !important;
  color: #ffffff;
  text-align: center;
  padding: 5px 10px;
  margin: 0px 10px;
  border-radius: 3px;
  text-decoration: none;
}
.iris_button a{
  display: inline-block;
  background-color: #2b7fda;
  box-shadow: 0 0 0 1px #2b7fda inset !important;
  color: #f4f4f4;
  text-align: center;
  padding: 5px 15px;
  margin: 0px 10px;
  border-radius: 3px;
  text-decoration: none;
  margin-right: 40px;
}

.iris_button a, .fn_button{
  display: inline-block;
  border: 1px solid #ffffff !important;
  background: none; 
  color: #f4f4f4;
  text-align: center;
  padding: 5px 10px;
  margin: 0px;
  border-radius: 3px;
  text-decoration: none;
  cursor: pointer;
}

.iris_button {
  margin: 0 5px;
}

.fn_button{
  line-height: 1.45;
  margin: 0 5px;
  cursor: pointer;
}

.ui.inverted.blue.button:active, .ui.inverted.blue.buttons .button:active{
  background-color: #2b7fda !important;
}
.send-link-button{
  width:135px !important;
}
li a:hover,.iris_button a:hover {
  background-color: #2b7fda;
  /* box-shadow: 0 0 0 1px #2b7fda inset !important; */
  color: #f4f4f4;
  transition: 0.7s;
}

li span:hover {
  color: #2b7fda;
}
.section-active-text{
  color: #2b7fda;
}

.section-name {
  margin-left: 15px;
}

.section-status-bar {
  background: var(--unnamed-color-2b7fda) 0% 0% no-repeat padding-box;
  background: #E1E1E1 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 5px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: auto;
  left: calc(32px + 2em);
}

.section-active-status-bar {
  background: var(--unnamed-color-2b7fda) 0% 0% no-repeat padding-box;
  background: #2B7FDA 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 5px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: auto;
  left: calc(32px + 2em);
}

.section-error-status-bar {
  background: var(--unnamed-color-2b7fda) 0% 0% no-repeat padding-box;
  background: #FF0000 0% 0% no-repeat padding-box;
  opacity: 1;
  width: 5px;
  margin-right: 10px;
  position: absolute;
  top: 0;
  bottom: 0;
  height: auto;
  left: calc(32px + 2em);
}

.section-error-text{
  color: #ff0000;
  text-transform: none;
}

.address-error-text{
  color: #ff0000;
}

.active {
  /* background-color: #2185d0; */
  /* box-shadow: 0 0 0 1px #2185d0 inset !important; */
  /* color: #f4f4f4; */
}
/* Override Sematic React Dropdown */
.ui.dropdown > .text {
  display: block;
}

.ui.search.dropdown > .text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  -webkit-appearance: none;
}

.ui.floating.dropdown .menu {
  margin-left: -113px !important;
  width: 180px !important;
  border: 0px;
}

.navbar .ui.floating.dropdown > .menu {
  margin-top: 0.3em !important;
  z-index: 1005;
}

.ui.dropdown .menu > .item {
  background: #000000 ;
  color: #f4f4f4 ;
}

.ui.dropdown .menu > .item:hover {
  background: #45424b ;
}
.drop-downclass .ui.dropdown .menu > .item {
  background: #f4f4f4 !important;
  color: #000000!important;
}
.drop-downclass .ui.dropdown .menu > .item:hover {
  background: #2185d0 !important;
  color: #f4f4f4!important;
}
.doc-li {
  display: block;
  float: left;
  clear: both;
  margin: 10px 20px;
}
.crossicon {
  width: 18px;
  height: 18px;
  background: url(./Images/cross.png) no-repeat center center;
  position: absolute;
  margin-left: 30px;
}

.tickicon {
  width: 18px;
  height: 18px;
  background: url(./Images/tick.png) no-repeat center center;
  background-size: 100%;
  position: absolute;
  margin-left: 3px;
}
.pdfIcon {
  width: 24px;
  height: 24px;
  background: url(./Images/pdf.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
}
.imageIcon {
  width: 24px;
  height: 24px;
  background: url(./Images/image.svg) no-repeat center center;
  background-size: contain;
  position: absolute;
}
.modal-content.ui.modal>.content{
  color:#2B7FDA;
}
.ui.modal>.content{
  color:#000;
}
/* Common */

.forgot-message {
  color: #000;
  float: right;
  font-size: 11px;
  letter-spacing: 0.86px;
  opacity: 1;
}

.error-message {
  color: #ff0000;
  text-align: left;
  font-size: 11px;
  letter-spacing: 0.86px;
  opacity: 1;
}

.email-error-message {
  color: #fff;
  font-size: 11px;
  letter-spacing: 0.86px;
  opacity: 1;
  font-weight: 600;
}

.address-error-message {
  color: white;
  text-align: left;
  padding-left: 30%;
  font-size: 14px;
  letter-spacing: 0.86px;
  opacity: 1;
  font-weight: 600;
}

.ui.fluid.search.selection.dropdown.form-input-platform {
  width: 98%;
}

.form-field .error-border {
  border: 1px solid #ff0000 !important;
}
.heading {
  margin: 20px 0px 30px;
}

.heading .date-filter {
  margin-right: 18px;
}

.heading .date-filter label {
  margin-right: 15px;
}

.heading .button-container button {
  margin-left: 10px;
}

.heading .audit-btn button {
  margin-left: 10px;
}

.heading-text {
  margin: 15px;
  text-align: left;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
}

.listing-heading-text {
  margin: 10px 50px;
  text-align: left;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #000000;
  opacity: 1;
}
.heading-text-center {
  text-align: center;
  font-size: 26px;
  letter-spacing: 1.2px;
  color: #2185d0;
  font-weight: 400;
}
.heading-text-left {
  text-align: left;
  font-size: 24px;
  color: #000000;
  text-transform: uppercase;
  font-family: "SEGUIBL";
}
.heading-center {
  margin:auto;
  display: table;
  font-size: 24px;
  color: #000000;
  text-transform: uppercase;
  font-family: "SEGUIBL";
}
.click-left-arrow {
  background: transparent url("./Images/backward-blue.svg") center no-repeat
    padding-box;
  opacity: 1;
  width: 32px;
  height: 32px;
  border: 1px solid #2b7fda;
  border-radius: 3px;
  float: right;
  margin-right: 10px;
}
.click-left-arrow:hover{
  background: #2b7fda url("./Images/backwardWhite.svg") center no-repeat
    padding-box;
}
.top-5{
  margin-top:5px !important;
}
.click-right-arrow {
  background: transparent url("./Images/forward-blue.svg") center no-repeat
    padding-box;
  opacity: 1;
  width: 32px;
  height: 32px;
  border: 1px solid #2b7fda;
  border-radius: 3px;
  float: right;
}
.click-right-arrow:hover{
  background: #2b7fda url("./Images/forwardWhite.svg") center no-repeat
  padding-box;
}
.grey-text {
  text-align: left;
  font-size: 28px;
  line-height: 52px;
  letter-spacing: 1.72px;
  color: #707070;
  font-weight: 500;
}
.heading-text-white{
  text-align: center;
  color: #f4f4f4;
  font-size: 28px;
  font-weight: 800;
}
.ui.icon.input>input{
  padding: 8px 5px!important;
}
.user_image {
  margin: 0px auto;
  border: 5px solid #2185d0;
  height: 98px;
  width: 98px;
  top: -30px;
  border-radius: 50%;
  background: red;
  opacity: 1;
  background-size: cover;
  background-position: center;
}

hr.top {
  border-bottom: 1px solid #2185d0;
  margin: 10px 20px;
}

.time-picker__input {
  height: 43px !important;
}

.form-content {
  margin: 25px;
  padding: 5px;
}
.login-form-content{
  cursor: pointer;
  margin:5px auto;
  padding:5px;
  width:45%
}
.form-field {
  clear: both;
  margin: 0 0 1em;
}
.ant-notification-notice-message{
  margin: 0px !important;
}

.fn-notification .ant-notification-notice-message {
  margin-left: 48px !important;
  margin-bottom: 4px !important;
}
.fn-success-notification .ant-notification-notice-description{
  margin-right: 10px;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #45424b !important;
  opacity: 1 !important;
}
::placeholder {
  /* WebKit browsers */
  color: #45424b !important;
  opacity: 1 !important;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #45424b;
  opacity: 1 !important;
}

::-moz-placeholder {
  /* Firefox 19+ */
  color: #45424b;
  opacity: 1 !important;
}

:-ms-input-placeholder {
  color: #45424b;
}
.form-input {
  box-shadow: 0 0 0 100px #fff inset !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 1.2px !important;
  height: 42px;
  color: #878787 !important;
  border-radius: 3px !important;
  border: 1px solid #f3f3f3 !important;
}
.form-input-login {
 /* box-shadow: 0 0 0 100px #fff inset !important;*/
  font-size: 14px !important;
  font-weight: 200;
  height: 38px;
  color: #878787 !important;
  border-radius: 2px !important;
  border: 1px solid #f3f3f3 !important;
  /*box-shadow: 0 0 5px 2px #707070 !important;*/
}

.input-percent {
    height: 42px;
    text-align: center;
    padding-top: 8px;
    width: 34px;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    opacity: 0.7;
    background: #ebebeb;
    margin-left: -34px;
}
.input-dollar{
    height: 42px;
    text-align: center;
    padding-top: 8px;
    width: 34px;
    font-weight: 600;
    font-size: 18px;
    position: absolute;
    opacity: 0.7;
    color:#2b7fda;
}
.padding-left-30{
  padding-left: 30px !important;
}
.padding-left-5{
  padding-left: 5px !important;
}
.form-input:hover ,.form-input:focus{
  border: 1px solid #2b7fda !important;
}
.form-conditional {
  box-shadow: 0 0 0 100px #fff inset !important;
  letter-spacing: 1.2px !important;
  border-radius: 3px !important;
}
.form--grinputey {
  box-shadow: 0 0 0 100px #f3f3f3 inset !important;
  letter-spacing: 1.2px !important;
  border-radius: 3px !important;
}
.ui.segment {
  color: #707070;
}
.semantic-date-input .field {
  width: 100% !important;
  display: block !important;
  height:42px !important;
  border: 1px solid #f3f3f3 !important;
}
.semantic-date-input input{
  cursor: pointer;
}
.center-buttons{
  width:100%;
  margin: 0 auto;  
  display: flex;
  justify-content: center;
}
.startapp-send-button{
  left: 50%;
  transform: translateX(-50%);
}
.mr-0{
  margin-right: 0px !important;
}
.mr-5{
  margin-right: 10px !important;
}
.min-height-0{
  min-height: 0px !important;
}
:where(.css-dev-only-do-not-override-1okl62o).ant-tooltip .ant-tooltip-inner{
  padding-left: 15px !important;
}
.block-button{
  display: block !important;
  margin-top: 10px !important;
}
.save-button {
  /* box-shadow: 0 0 0 1px #2b7fda inset !important; */
  border-radius: 3px !important;
  min-width: 120px;
  height: 40px;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  position: relative;
  margin:0px 5px;
  color: #f4f4f4;
  cursor: pointer;
}
.save-button:active,
.save-button:hover,.save-button:focus {
  background-color: #2b7fda;
  /* box-shadow: 0 0 0 1px #2b7fda inset !important; */
  color: #f4f4f4;
  transition: 0.7s;
}
.active-btn{
  background-color: #2185d0 !important;
  color: #fff !important;
  text-shadow: none;
  padding: 5px 20px;
  border: 1px solid #2185d0;
  border-radius: 3px;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  cursor: pointer;
}
.not-active-btn{
  color: #2185d0 !important;
  background-color: #fff !important;
  text-shadow: none;
  padding: 5px 20px;
  border: 1px solid #2185d0;
  border-radius: 3px;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  cursor: pointer;
}
.save-button-right {
  /* box-shadow: 0 0 0 1px #2b7fda inset !important; */
  border-radius: 3px !important;
  min-width: 100px;
  height: 38px;
  font-size: 16px !important;
  font-weight: 600 !important;
  white-space: nowrap;
  position: absolute;
  margin-top: -11px !important;
  right: 20% !important;
  color: #f4f4f4;
}
.save-button-right:active,
.save-button-right:hover,.save-button-right:focus {
  background-color: #2b7fda;
  /* box-shadow: 0 0 0 1px #2b7fda inset !important; */
  color: #f4f4f4;
  transition: 0.7s;
}
.ui.form .field .ui.input,
.ui.form .fields .field .ui.input,
.ui.form .wide.field .ui.input {
  width: 100% !important;
}

.form-input-label {
  text-align: left;
  letter-spacing: 0.9px;
  color: #000;
  opacity: 1;
}
.form-checkbox-label {
  text-align: left;
  letter-spacing: 0.9px;
  color: #494949;
  opacity: 1;
  font-size: 16px;
  font-weight: 600;
  margin-top: 10px;
}
.label-checkbox {
  text-align: left;
  letter-spacing: 0.9px;
  color: #ccc;
  opacity: 1;
  font-size: 18px;
}
.top-container {
  background: #2b7fda;
  height: auto;
  width: 100%;
  /* margin-top: 50px; */
}
.top-content {
  margin: 0 18%;
  padding: 20px 5px;
}
.top-content-label {
  color: #fff !important;
}
.margin-bottom-30 {
  margin-bottom: 30px !important;
}
.form-container {
  height: auto;
  width: 100%;
  float: left;
}
.main-content {
  margin: 20px 17%;
  float: left;
}
.left-content {
  width: 33%;
  float: left;
  height: auto;
  margin-bottom: 20px;
  margin-left: -42px;
}
.right-content {
  width: 68%;
  float: left;
  height: auto;
  margin-bottom: 20px;
}
.startapp-content {
  padding-bottom: 20px;
  margin: 25px 0px 25px 20%;
  height: auto;
}
.body-content {
  margin: 25px 3%;
  padding: 5px;
  /* min-height: 440px; */
  min-height: calc(100vh - 50px);
  /* max-height: 100%; */
}
.thankyou-body-content {
  margin: 25px 3%;
  padding: 5px;
  min-height: 70vh;
  display: flex;
}
.listapp-body-content{
  margin: 5px 5px;
  padding: 5px;
  min-height: calc(100vh - 50px);
  max-height: 100%; 
}
.sendapp-body-content{
  margin: 25px 0px;
  padding: 5px;
}
.sendapp-actions{
 align-items: center;
 display: block;
 margin-left: 23px;
}
.body-content p {
  font-size: 18px;
  color: #45424b;
}
/* Right side form fields*/
.right-form-content {
  background-color: #fff;
}
.form-contents {
  background-color: #f3f3f3;
  padding-bottom: 20px;
  margin-bottom: 25px;
  height: auto;
}
.form-heading {
  letter-spacing: 0px;
  color: #2b7fda;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px;
  padding: 15px 20px 0;
}
.form-headingleftpadding {
  letter-spacing: 0px;
  color: #2b7fda;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px;
  padding: 14px 50px 0;
}
.form-heading-message {
  letter-spacing: 0px;
  color: #2b7fda;
  text-transform: uppercase;
  opacity: 1;
  font-size: 18px;
  padding: 14px 20px 0;
  max-width: 90%;
}
.form-fields {
  padding: 5px 20px;
}
hr.top {
  border-bottom: 1px solid #2b7fda;
}
.input-field {
  width: 90% !important;
  display: inline-block;
}
.ui.radio.checkbox input:checked ~ .box:after,
.ui.radio.checkbox input:checked ~ label:after {
  background-color: #2b7fda !important;
}

.ui.selection.dropdown > .dropdown.icon {
  background-color: #ebebeb;
  margin-right: -13px;
  height: 100%;
}

.ui.inverted.blue.button,
.ui.inverted.blue.buttons .button {
  background-color: transparent;
  box-shadow: 0 0 0 1px #2b7fda inset !important;
  color: #2b7fda;
  margin-right: 20px;
  border-radius: 3px;
  padding: 10px 20px;
}
.ui.inverted.blue.button:hover,
.ui.inverted.blue.buttons .button:hover {
  background-color: #2b7fda !important;
  margin-right: 20px;
  transition: 0.7s;
  
}
.startapp-action-button .ui.inverted.blue.button:hover {
  background-color: transparent !important;
  color: #2b7fda !important;
  box-shadow: 0 0 0 1px #2b7fda inset !important;
}

.ui.inverted.blue.button:focus, .ui.inverted.blue.buttons .button:focus{
  background-color: #2b7fda !important;
  margin-right: 20px;
  transition: 0.7s;
}
.ui.inverted.blue.button:visited, .ui.inverted.blue.buttons .button.button:visiste{
  background-color: #2b7fda !important;
  margin-right: 20px;
  transition: 0.7s;
}
.padding-right-100{
  padding-right: 100px;
}
.p-15{
  padding:0px 15px !important;
  border-radius: 5px solid !important;
}
/*  Login Page  */
.login-container {
  width: 100%;
  height: 100vh;
  background: transparent url("./Images/login-bg-image-new.jpg");
  opacity: 1;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.align-fields-cnt{
    display: flex !important;
    justify-content: center !important;
}
.resetPasswordTtl{
  width: 60%;
  display: flex;
  justify-content: flex-end;
  margin: 0px;
}
.header-resetPassword{
  display: flex;
}
.close-button-resetPassword{
  width: 40%;
  display: flex;
  justify-content: end;

}
.err-message {
  display: flex !important;
  flex-direction: column !important;
  align-items: normal !important;
}
.message{
  margin: 10px 2px 0px;
  color: red;
}
/* .adduser-container{
  width: 100%;
  margin-top: 50px;
  padding: 30px 0px;
  background: url("./Images/login-bg-image.jpg") no-repeat center center fixed; 
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  opacity: 1;
  overflow: hidden;
} */
.thankyou-page-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.admin-login-content{
  width: 40%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.596);
  border-radius: 10px;
  box-shadow: 20px 20px 50px #00000063, 
  -30px -30px 60px #00000063;
  text-align: center;
  color: #102234;
}
.add-user{
  width: 100%;
  background-color: #0080c8;
  
}
.adduser-container{
  width: 100%;
  color: #ffffff;
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  padding: 20px;
}
.adduser-content{
  border-radius: 10px;
  padding: 50px ;
  width: 60%;
  
}
.login-content {
  margin: 13% auto;
  width: 50%;
  position: relative;
}
.login-left-content {
  float: left;
  width: 60%;
  height: 500px;
}
.login-heading {
  margin: 0px;
  padding: 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
/*.login-heading-black {
  font-size: 18px;
  font-weight:800;
  letter-spacing: 0.8px;
  color: #000;
  opacity: 1;
  padding-bottom: 10px;
  text-align: center;
  text-transform: capitalize;
  font-family: "SEGOEUIB";
}*/
.login-heading-white{
  margin: 15px auto;
}
.login-heading-blue-smart{
  margin-top: 20px;
}
.login-heading-blue{
  margin-left: 10px;
}
.login-heading-white span{
  font-family: 'Raleway', sans-serif;
  font-weight: 600;
  font-size: 34px;
  color: #ffffff;
}
.verify-email-title{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: 'Raleway', sans-serif;
  font-weight: 500;
  font-size: 16px;
}
.login-heading-blue span{
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 58px;
  color: #2b7fda;
}
.login-heading-blue-smart span{
  font-family: 'Montserrat', sans-serif;
  font-weight: 600;
  font-size: 38px;
  color: #2b7fda;
}
.login-input-label {
  text-align: left;
  color: #000;
  opacity: 1;
  /*text-transform: capitalize;*/
  font-family: "SEGOEUI";
}
.auth-button {
  width: 50%;
  height: 36px;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.75px;
  background: #2b7fda;
  color: #fff;
  padding: 5px;
  text-transform: uppercase;
  font-family: "SEGOEUI";
  /* -webkit-box-shadow: 0 0 5px 2px #333;
  -moz-box-shadow: 0 0 5px 2px #333; */
  /* box-shadow: 0 0 5px 2px #333; */
  border-radius: 1.28571429rem;
  /* border: 1px solid; */
  position: absolute;
  margin: 0px !important;
  right: 0px;
  cursor: pointer;
  /* margin-top: 20px !important; */
}
.auth-button:active,
.auth-button:hover {
  /* background-color: #2185d0 !important; */
  color: #fff;
  text-shadow: none;
}
.forgot-text {
  color: #f4f4f4;
  font-size: 14px;
  text-align: center;
  margin-top: 15px;
}
.eye-icon-pwd {
  position: absolute;
  right: 5px;
  padding-top: 9px !important;
  cursor: pointer;
}
.eye-icon-owner-dlId {
  position: absolute;
  margin-left: -30px !important;
  padding-top: 10px !important;
  cursor: pointer;
}
.ui.form .inline.field > .selection.dropdown,
.ui.form .inline.fields .field > .selection.dropdown {
  width: 100%;
}
.ui.form .inline.fields .field{
  padding: 0 0.5em 0 0;
}
.method-of-sales {
  width: 22%!important;
}
/* Nav Bar Right Image and Drop down */
.navbar {
  width: 50%;
  margin-left: 5%;
  display: flex;
  flex-direction: row-reverse;
  align-items: center;
}

/* Navbar links */

.right-header-image {
  float: right;
  display: block;
  margin: 0px auto;
  box-shadow: 0 0 0 1px #ffffff inset !important;
  margin: 8px 10px;
  height: 32px;
  width: 32px;
  border-radius: 3px;
  background: transparent url("./Images/top-right-icon.png") no-repeat center;
  opacity: 1;
}

.right-logout {
  float: right;
  height: 58px;
  width: 45px;
  margin: 2px 10px;
  border-radius: 50%;
  opacity: 1;
  background-size: cover !important;
  background-position: center !important;
  z-index: 1002;
  display: flex;
  justify-content: center;
  align-items: center;
}
.logout-icon{
  margin: 10px;
}
.user-name {
  z-index: 1007;
  /* color: #f4f4f4; */
  font-size: 18px;
  font-weight: 600;
}
.warning-text{
  color:#ff0000;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
}
.warning-text-message{
  color:#ff0000;
  font-size: 13px;
  font-weight: 400;
  text-align: left;
  margin-bottom: 10px;
}
.topmail-warning-text{
  color:white;
  font-weight: 600;
  font-size: 14px;
  padding-left: 25.4%;
}
.navbar .ui.floating.dropdown .menu {
  margin-left: -113px !important;
  width: 180px !important;
  border: 0px;
}
.ui.floating.dropdown .menu {
  margin-left: -113px !important;
  width: 180px !important;
  border: 0px;
}
.navbar .ui.floating.dropdown > .menu {
  margin-top: 0.3em !important;
  z-index: 1005;
}
.ui.floating.dropdown > .menu {
  margin-top: 0.3em !important;
  z-index: 1005;
}

.navbar .ui.dropdown .menu > .item {
  background: #102234 !important;
  color: #f4f4f4 !important;
}
.ui.dropdown .menu > .item {
  background: #102234 !important;
  color: #f4f4f4 !important;
}
.ui.styled.accordion {
  width: 100%;
}
/* Left Navbar */
.vertical-list {
  position: relative;
  width: 100%;
  display: flex;
  flex: 1 0 auto;
  flex-wrap: wrap;
  flex-direction: row;
  vertical-align: middle;
  justify-content: flex-start;
  padding: 1.14285714em 2em;
  cursor: pointer;
}
.vertical-list a {
  color: #878787 !important;
  text-decoration: none;
  font-size: 18px;
  margin-left: 10px;
}
.vertical-list:hover {
  cursor: pointer;
}
.green-arrow-icon {
  background-image: url("./Images/tick.png");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
  height: 14px;
  width: 30px;
  float: left;
  margin-top: 3px;
}
.yellow-arrow-icon {
  background-image: url("./Images/cross.png");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
  float: left;
  height: 14px;
  width: 30px;
  margin-top:3px;
}
.grey-arrow-icon {
  background-image: url("./Images/cross.png");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
  float: left;
  height: 14px;
  width: 30px;
  margin-top:3px;
}
.message-section-icon {
  float: left;
  height: 14px;
  width: 30px;
  margin-top:3px;
}

.red-arrow-icon {
  background-image: url("./Images/cross.png");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
  float: left;
  height: 14px;
  width: 30px;
  margin-top:3px;
}
.nowrap{
  white-space: nowrap !important;
}
.orange-arrow-icon {
  background-image: url("./Images/cross.png");
  background-position: center;
  background-repeat: no-repeat;
  opacity: 1;
  background-size: contain;
  float: left;
  height: 14px;
  width: 30px;
  margin-top:3px;
}
.thankyou-text{
  font-weight: 600;
  font-size: 20px;
  text-align: center;
  margin-bottom: 45px;
}
.additiona_location{
  padding: 15px 25px 15px 25px !important;
  /* background: #4472C4 !important; */
  color: white !important;
  border-radius: 7px !important;
}
.button_additiona_location{
  text-align: center;
  margin: 30px;
}
.text-duplicate{
  font-weight: 500;
  font-size: 18px;
  text-align: center;
  margin-bottom: 7px;
}
.applist-container{
  min-height: 80vh;
}
/* footer */
.footerArea{
  bottom: 0;
}
.footerLogoArea {
  background: #102234;
  float: left;
  width: 100%;
}
.blockArea {
  width: 85%;
  margin: 0 auto;
  height: 125px;
  padding: 10px;
}
.footer-blockArea{
  width: 65%;
  margin: 0 auto;
  height: px;
  padding: 10px;
}
.footer-logo {
  float: left;
  position: absolute;
  margin: 15px;
  height: 80px;
  width: 12%;
  opacity: 1;
  background-position: center;
  background-size: contain;
}
.footer-logo > img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.footer-p {
  font-size: 14px;
  color: #fff;
  float: right;
  width: 77%;
  margin-top: 18px;
  margin-right: 20px;
}
.footerCardArea {
  float: left;
  width: 100%;
  height: auto;
}
.footer-text {
  font-size: 12px;
  color: #000;
  width: 81%;
  text-align: left;
  margin-top: 30px;
  margin-left: 20px;
}
.texthover:hover {
  color: #878787;
}
.cards-section {
  height: 45px;
  float: left;
  padding: 20px 0px;
}
.cards {
  display: inline;
  width: 100px;
  margin-left: 30px;
  vertical-align: middle;
}
.cards:first-child {
  margin-left: 20px;
}
.blocked-text {
  float: left;
  width: 100%;
  margin-bottom: 10px;
}
.small-text {
  padding-top: 15px;
  font-size: 11px;
}
.bottom-chat-icon {
  margin-left: 8%;
  margin-top: -1.5%;
  height: 45px;
  width: 46px;
  position: absolute;
  background: transparent url("./Images/footerchaticon.svg") no-repeat;
  opacity: 1;
  background-position: center;
  background-size: contain;
}
.ui.grey.button,
.ui.grey.buttons .button {
  background-color: #53738e !important;
  margin: 10px !important;
}
.ui.top.right.pointing.dropdown > .menu {
  margin: 1px 0 0 !important;
}
.overlay {
  position: absolute;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: hsl(0, 0%, 100%);
  z-index: 101;
  cursor: pointer;
  opacity: 0.8;
}

.overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
}
.overlay-text {
  text-align: center;
  margin: 20px;
  clear: both;
  display: block;
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1.5px;
  color: #2B7FDA;
  opacity: 1;
  line-height: normal;
}
.loader-msg {
  font-size: 24px;
  font-weight: 600;
  letter-spacing: 1px;
  /* color: #2b80dad0 !important; */
  margin-top: 100px;
  opacity: 1;
}
.margin-left-40{
  margin-left:40% !important;
}
.margin-left-10{
  margin-left:10px !important;
}
.center-aligned{
  text-align: center;
}
.grey-outline{
  border:1px solid #2b7fda;
  padding:10px;
  margin-bottom: 20px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
/* Table View */
.ui.table {
  border: none;
}
.text-wrap{
  word-wrap: break-word;
}
.ui.table thead th {
  background: #2b7fda;
  color: #f4f4f4;
  border-radius: 0px !important;
  vertical-align: top;
  white-space: nowrap;
}

.ui.table thead th.t-data {
  padding-left: 0;
  padding-right: 0;
}

.ui.table thead tr:first-child > th:first-child,
.ui.table thead tr:first-child > th:last-child {
  vertical-align: top;
}
.ui.striped.table tbody tr{
  cursor: pointer;
}
.ui.structured.celled.table tr td {
  color: #112234 !important;
  text-transform: uppercase;
}
.ui.structured.celled.table tr td,
.ui.structured.celled.table tr th {
  border: none;
  width: 10%;
  word-break: break-word;
}

/* .users-table-sa th {
  max-width: 7.69% !important;
} */
.list-table-view {
  display: flex;
  flex-direction: column;
}
.display_oneline{
  display: flex;
}
.list-table-view .ui.icon.input>i.icon{
  top:10px;
  right:-10px;
}

.view-text{
  font-size: 8px;
}

.view-text i.icon{
  font-size: 1.8em; 
}

.list-table-view tr , .list-table-view-viewonly tr{
  display: flex;
}

.list-table-view thead, .list-table-view tbody {
  width: 100%;
}

.list-table-view-viewonly thead, .list-table-view-viewonly tbody {
  width: 110%;
}

.list-table-view th, .list-table-view td {
  width: 10%;
}

.list-table-view input {
  font-size: 0.5vw;
}

.table-search-icon {
  height: 28px;
  width: 28px;
  position: absolute;
  background: transparent url("./Images/search-icon.svg") no-repeat;
  opacity: 1;
  margin-left: -28px;
  margin-top: 15px;
}
.search-input{
  max-width:6.2vw;
}
.modal-text {
  font-size: 18px;
  color: #000;
  text-align: left !important;
  width:92.5%;
  margin-left: 30px;
}
.ui.striped.table tbody tr:nth-child(n),
.ui.striped.table > tr:nth-child(n) {
  background-color: #ebebeb;
}
.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table > tr:nth-child(2n) {
  background-color: #fff;
}
.edit-icon {
  float: left;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/edit.svg") no-repeat center;
  opacity: 1;
  margin: 2px;
}
.upload-icon {
  float: left;
  margin: 2px;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/upload_doc.svg") no-repeat center;
  opacity: 1;
}

.delete-icon {
  float: left;
  margin: 2px;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/delete-icon.png") no-repeat center;
  opacity: 1;
  background-size: contain;
}
.deactivate-icon {
  float: left;
  height: 25px;
  width: 25px;
  margin: 2px;
  background: transparent url("./Images/withdraw.svg") no-repeat center;
  opacity: 1;
}
.reset-password-icon {
  float: left;
  margin: 0px 5px;
  height: 30px;
  width: 30px;
  background: transparent url("./Images/reset-password-icon.png") no-repeat center;
  opacity: 1;
  background-size: contain;
}

.email-activity-icon {
  float: left;
  margin: 2px;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/email-activity.png") no-repeat center;
  opacity: 1;
  background-size: contain;
}

.view-icon {
  float: left;
  margin: 2px;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/eye.png") no-repeat center;
  opacity: 1;
  background-size: contain;
}
.close-button-sendToTrinity{
  display: flex;
  justify-content: flex-end;
}
.send-icon {
  float: left;
  height: 25px;
  width: 25px;
  margin: 2px;
  background: transparent url("./Images/mail.svg") no-repeat center;
  opacity: 1;
}
.withdraw-icon {
  float: left;
  height: 25px;
  width: 25px;
  margin: 2px;
  background: transparent url("./Images/withdraw.svg") no-repeat center;
  opacity: 1;
}
.duplicate-icon {
  float: left;
  height: 25px;
  width: 25px;
  border-radius: 3px;
  background: transparent url("./Images/copy.svg") no-repeat center;
  opacity: 1;
  margin: 2px;
}
.clone-icon {
  float: left;
  height: 25px;
  width: 25px;
  max-height: 25px;
  max-width: 25px;
  border-radius: 3px;
  background: transparent url("./Images/duplicate-icon.png") no-repeat center;
  opacity: 1;
  margin: 2px;
  background-size: 100% 100%;
}
.uw-icon {
  float: left;
  height: 25px;
  width: 25px;
  background: transparent url("./Images/underwritting.svg") no-repeat center;
  opacity: 1;
  margin: 2px;
}
.small-icon{
  height: 20px;
  width: 20px;
}

.pricing-table.ui.structured.celled.table tr td{
  text-transform: capitalize !important; 
}
.other-table.ui.structured.celled.table tr td{
  text-transform: capitalize !important; 
}
.other-table.ui.table thead th {
  background: #f4f4f4;
  color: #000;
  border-radius: 0px !important;
  vertical-align: top;
  white-space: nowrap;
}
.pricing-table.ui.table thead th {
  background: #f4f4f4;
  color: #000;
  border-radius: 0px !important;
  vertical-align: top;
  white-space: nowrap;
}
.pricing-table tbody tr td input:disabled{
  pointer-events:none;
  opacity: 25;
}
.other-table tbody tr td input:disabled{
  pointer-events:none;
  opacity: 25;
}
.margin-right-10{
  margin-right:10px;
}
.border-left-field{
  border-left:1px solid #2b7fda !important;
}
.margin-right-8{
  margin-right:8px !important;
}
.margin-left-5{
  margin-left:5px !important;
}
.useLegalAddress{
  position: absolute;
  padding-left: 76% !important;
  padding-top: 14px;
  font-weight: 600;
  margin-top:-11px;
}
/* Upload File Section */
.upload-section{
    position: relative;
    background: #fff;
    margin: 1rem 0;
    padding: 1em 0em;
    border-radius: .28571429rem;
}
.top-section{
  margin-bottom:20px;
}
.left-heading{
  font-size:20px;
  font-family: "SEGUISB";
}
.right-button{
  float: right;
  box-shadow: 0 0 0 1px #2B7FDA inset !important;
  margin: 8px 10px;
  padding:10px;
  text-align: center;
  height: 40px;
  width: 100px;
  border-radius: 5px;
  background-color:#2B7FDA;
  color:#ffffff;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
}
.doc-name{
    box-shadow: 0 0 0 1px #2B7FDA inset !important;
    margin: 8px 10px;
    display: inline-block;
    padding:10px;
    text-align: center;
    height: 60px;
    width: 60px;
    border-radius: 3px;
    color:#2B7FDA;
    font-size: 16px;
    font-weight: 600;
}
.doc-name:hover,.doc-name:active{
  background-color: #2B7FDA;
  color: #FFFFFF;  
  transition: 0.7s;
}

.docupload-icon {
  box-shadow: 0 0 0 1px #2b7fda inset !important;
  margin: 3px  0 0 37%;
  height: 42px;
  width: 42px;
  border-radius: 3px;
  background: transparent url("./Images/upload.svg") no-repeat center;
  opacity: 1;
  position: absolute;
}

.navigationleft-icon {
  height: 24px;
  width: 24px;
  background: transparent url("./Images/arrow_previous.svg") no-repeat center;
  opacity: 1;
  position: absolute;
  margin-left: -2.0em;
  cursor: pointer;
}

.navigationright-icon {
  height: 24px !important;
  width: 24px !important;
  background: transparent url("./Images/arrow_next.svg") no-repeat center;
  opacity: 1 !important;
  position: absolute !important;
  margin-left: 0.8em;
  cursor: pointer;
  
}

.blue-text{
  color: #2b7fda;
}
.highlight-text{
  color:red;
  font: 400;
  font-size: 11px;
  margin-left:30px;
}
/* DROPZONE CSS */
.doc-contain-area{
  width:100%;
  height:auto;
  display: block;
  overflow: hidden;
}
.dropzone-title {
  font-size: 1rem;
  text-align: left;
  color: #000;
  font-weight: 600;
  text-transform: uppercase;
}
.dropzone{
  border:1px solid #2b7fda;
}
.dropzone-content {
  background-color: white;
  width: 100%;
}

.dropzone-container p {
  color: #1d3449;
  text-align: center;
}
.pointer-none{
  pointer-events: none;
}

.drop-container {
  align-items: center;
  margin: 40px 0px;
  width: 100%;
  background-color: #f3f3f3;
  padding:10px;
  border-radius: 5px;
  border: 1px dashed #878787;
  box-shadow: 0 0 0 5px #fff inset !important;
}
.doc-button-section{
  display: inline-block;
  clear: both;
}
progress{
  width:500px;
}
.dzu-inputLabelWithFiles{
  margin-bottom:30px;
}
.dzu-dropzone{
  overflow: hidden !important;
}
.rightAlign {
  float: right;
}
.margin-right-0{
  margin-right: 0px !important;
}
.drop-message {
  color: #4aa1f3;
  font-family: Arial;
  font-size: 20px;
  margin-left: 45%;
  cursor: pointer;
}
.file-display-container {
  height: auto;
  width:100%;

}

.file-status-bar {
  width: 100%;
  height: 50px;
  display: inline-flex;
}

.file-status-bar > div {
  overflow: hidden;
}

.file-type {
  display: inline-block !important;
  position: absolute;
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  margin-top: 25px;
  padding: 0 4px;
  border-radius: 2px;
  box-shadow: 1px 1px 2px #abc;
  color: #fff;
  background: #0080c8;
  text-transform: uppercase;
}

.file-name {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #4aa1f3;
}

.file-error {
  display: inline-block;
  vertical-align: top;
  margin-left: 50px;
  color: #9aa9bb;
}

.file-error-message {
  color: red;
}

.file-type-logo {
  width: 50px;
  height: 50px;
  background: url(./Images/generic.png) no-repeat center center;
  position: absolute;
  background-size: cover;
  background-position: center;
}

.file-size {
  display: inline-block;
  vertical-align: top;
  color: #30693d;
  margin-left: 10px;
  margin-right: 5px;
  margin-left: 10px;
  color: #444242;
  font-weight: 700;
  font-size: 14px;
}

.file-remove {
  position: absolute;
  top: 20px;
  right: 10px;
  line-height: 15px;
  cursor: pointer;
  color: red;
  margin-right: -10px;
}
.margin-top-10 {
  margin-top: 10px;
}
.px-0{
  padding-left: 0px;
  padding-right: 0px;
}
.grey-border {
  border: 1px solid #ccc;
  padding: 10px;
}

.disableddiv {
  pointer-events: none;
  opacity: 0.8;
}
.not-active {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.4;
}

.send-link-user-table-URL {
  text-transform: unset !important;
}

.link-details-field-container {
  display: flex;
}

.display-flex-row-center{
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.display-flex-row-end{
  display: flex;
  flex-direction: row-reverse;
  width: 30%;
}
.display-flex-row-start{
  display: flex;
  flex-direction: row;
  width: 70%;
  padding-left: 20px;
}

.link-details-label-container {
  width: 50%;
}

.link-details-label {
  position: relative;
}

.redirect-link-text {
  color: #ffffff;
}

.redirect-link-text:hover{
  color:#FFF !important;
}

.required-text{
  color:#ff0000;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  margin-left: 5px;
}

.success-text{
  color:#009225;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  margin-left: 5px;
  margin-top: 3px;
}

.not-required .required-text {
  display: none;
}

.not-filled{
  border: 1px solid #ff0606 !important;
  box-shadow: 0 0 0 100px #fff inset !important;
  font-size: 16px !important;
  font-weight: 400;
  letter-spacing: 1.2px !important;
  height: 42px;
  color: #878787 !important;
  border-radius: 3px !important;
}

.not-required .not-filled {
  border: 1px solid #f3f3f3 !important;
}

/* Not Found Page */
.not-found {
  margin-top: 20px;
  text-align: center;
  color: #000000;
  margin-left: 15px;
  font-size: 24px;
  line-height: 52px;
  letter-spacing: 1.72px;
  opacity: 1;
}

.product-cost-wrapper {
  display: flex;
  justify-content:flex-start ;
  align-items: center;
}

.product-cost-wrapper input {
  width: unset !important;
}

.product-cost-wrapper .product-cost-label1 {
  margin-right: 10px !important;
}

.product-cost-wrapper .product-cost-label2 {
  margin-left: 10px !important;
}

.month-check-box {
  margin: 0 0.5em 0 0 !important;
  min-width: 60px !important;
}

.seasonal-month-wrapper, .billing-month-wrapper {
  flex-wrap: wrap !important;
}

.billing-field {
  min-width: 200px;
  margin: 5px 0 !important;
}

.billing-field .form-input {
  width: 100px !important;
}

.billing-field .input-percent {
  width: 40px !important;
  position: relative !important;
  margin-left: -30px !important;
}

.billing-field .ui.checkbox {
  width: 17px;
}

.billing-field label {
  width: 80px !important;
}

.startapp-action-button {
  display: flex;
  justify-content: center;
  transform: scale(1.4);
}

.startapp-action-button .startapp-send-button {
  left: unset !important;
  transform: unset !important;
}

.ant-picker-suffix, .ant-picker-clear {
  display: none;
}

.ant-picker-input>input {
  border: none !important;
}

.ui.form .field>label {
  min-height: 28px;
}

.email-activity-header {
  display: flex;
  justify-content: space-between;
}

.email-activity-close-button {
  background: none;
  border: none;
  cursor: pointer;
  display: inline-flex;
  padding-right: 0;
}

.email-activity-modal .actions {
  display: flex;
  justify-content: center;
}
.close-button{
  width: 100%;
  display: flex;
  justify-content: end;
}

.word-wrap{
  word-wrap: 'anywhere' !important;
  -ms-word-break: break-all;
  word-break: break-all;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  white-space: pre-line;
}

.input-group-addon {
  padding: 6px 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 1;
  color: #555;
  text-align: center;
  background-color: #eee;
  border: 1px solid #ccc;
  width: 1%;
  white-space: nowrap;
  vertical-align: middle;
  display: table-cell;
}

.prefix input {
  border-radius: 0px 4px 4px 0px;
}
.prefix .input-group-addon {
  border-right: 0;
  border-radius: 4px 0px 0px 4px;
}
.input-group-addon.prefix {
  border-radius: 4px 0px 0px 4px;
  border-right: 0;
}
.input-group {
  position: relative;
  display: table;
  border-collapse: separate;
}
.header_loginPg {
  top: 0;
  height: 58px;
  background: none; 
  position: fixed;
  width: 100%;
  z-index: 100;
  display: flex;
  flex-direction: row;
}

.t-header{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.t-row{
  width: 100%;
  display: flex;
  flex-direction: row;
}
.t-data input{
  font-size: 0.4vw;
}

/* .admin th,td{
  width: 10.1% !important;
} */
/* .user-table-view {
  display: flex;
  flex-direction: column;
}
.user-table-view .ui.icon.input>i.icon{
  top:10px;
  right:-10px;
}

.user-table-view tr {
  display: flex;
}

.user-table-view thead, .user-table-view tbody {
  width: 100%;
}

.user-table-view th, .user-table-view td {
  width: 11.1% !important;
}

.admin.user-table-view th, .admin.user-table-view td {
  width: 10% !important;
}

.super-admin.user-table-view th, .super-admin.user-table-view td {
  width: 8.39% !important;
}


.admin.office-table-view th, .admin.office-table-view td {
  width: 10% !important;
}
.super-admin.office-table-view th, .super-admin.office-table-view td {
  width: 9.09% !important;
}

.user-table-view input {
  font-size: 0.5vw;
} */

/* .office-table-view {
  display: flex;
  flex-direction: column;
}
.office-table-view .ui.icon.input>i.icon{
  top:10px;
  right:-10px;
}

.office-table-view tr {
  display: flex;
} */
.owner-email-validation{
  font-size: 12px;
  color: red !important;
}
.business-email-validation{
  font-size: 12px;
  color: red !important;
}

/* .office-table-view thead, .office-table-view tbody {
  width: 100%;
}

.office-table-view th, .office-table-view td {
  width: 12.5%;
}

.office-table-view input {
  font-size: 0.5vw;
} */

.pointer{
  cursor: pointer;
}

.info1-icon{
  min-height: 11px;
  min-width: 11px;
  background: transparent url("./Images/field_info.png");
  opacity: 1;
  background-size: 100% 100%;
  margin-left:8px ;
  display: inline-block;
}
.info1-icon-merchant{
  height: 11px;
  width: 11px;
  background: transparent url("./Images/field_info.png");
  opacity: 1;
  background-size: 100% 100%;
  margin-left:5px ;
}
.fields label{
  display: flex !important;
  align-items: center;
}

.thankyou-body-content {
  margin: 25px 3%;
  padding: 5px;
  min-height: 70vh;
  display: flex;
}
.thankyou-page-container{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.fields .no-bold{
  font-weight: 700;
}

.active-link, .ui.table td.active, .ui.table tr.active {
  background: #a6d1ff !important;
}

/* MEDIA QUERY FOR PHONES */
@media only screen and (max-width: 768px) {
  .login-button {
    width: 100%;
    margin: 0;
  }
  .auth-button {
    width: 50%;
    margin-bottom: 10px !important;
  }
  .useLegalAddress.ui.checkbox .box,.useLegalAddress .ui.checkbox label {
    font-size:8px !important;
    padding-left:25px !important;
    white-space: nowrap;
    
  }
  .file-display-container{
    width: 80%;
  }
}
@media only screen and (max-width: 1024px) {
  .login-button {
    width: 100%;
    margin: 0;
  }
  .auth-button {
    width: 50%;
    margin-bottom: 10px !important;
  }
  .useLegalAddress.ui.checkbox .box,.useLegalAddress .ui.checkbox label {
    font-size:10px !important;
    padding-left:25px !important;
    white-space: nowrap;
  }
  .admin-login-content{
    width: 90%;
    display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  }
  .login-heading-white span{
    font-family: 'Raleway', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: #ffffff;
  }
  .login-heading-blue span{
    font-family: 'Montserrat', sans-serif;
    font-weight: 700;
    font-size: 44px;
    color: #2b7fda;
  }
  .login-heading-blue-smart span{
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: #2b7fda;
  }
}
@media only screen and (max-width: 1200px) {
  .login-button {
    width: 100%;
    margin: 0;
  }
  .auth-button {
    width: 50%;
    margin-bottom: 10px !important;
  }
  .useLegalAddress.ui.checkbox .box,.useLegalAddress .ui.checkbox label {
    font-size:11px !important;
    padding-left:25px !important;
    white-space: nowrap;
  }
}


.startapp.ui.form .field input::placeholder,
.startapp.ui.form .field input::-webkit-input-placeholder{
  visibility: hidden;
}

.listapp-container .ant-pagination {
  display: flex;
  justify-content: flex-end; /* Align pagination to the right */
}

.listapp-container .ant-pagination-item {
  margin-left: 1px; /* Adjust spacing between pagination items */
}

.listapp-container .ant-pagination-item ,
.listapp-container .ant-pagination-prev,
.listapp-container .ant-pagination-next {
  display: inline-block;
  width: 32px; /* Set a fixed width */
  height: 32px; /* Set a fixed height */
  text-align: center; /* Center the text horizontally */
  line-height: 30px; /* Center the text vertically */
  border: 1px solid #ccc;
  text-decoration: none;
  color: #333;
  border-radius: 0px; /* Add border-radius for rounded corners */
}

.listapp-container .ant-pagination-item:hover,
.listapp-container .ant-pagination-prev:hover,
.listapp-container .ant-pagination-next:hover {
  border-color: #1890ff;
}

.listapp-container .ant-pagination-item a,
.listapp-container .ant-pagination-prev,
.listapp-container .ant-pagination-next {
  margin-left: 3px; /* Adjust spacing for previous and next buttons */
}
.listapp-container .ant-pagination-item a{
  display: inline-block;
  height: 100%;
  width: min-content;
  margin: 0;
}

.listapp-container .ant-pagination-item-active {
  border-color: #1890ff; /* Change the border color for the active item */
}



.list-app-table-header {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer !important;
}

.list-app-table-header i.icon {
  margin: 0 0 .5rem 0;
}

@media only screen and (max-width: 480px) {
  
  .startapp.ui.form .field input::placeholder,
  .startapp.ui.form .field input::-webkit-input-placeholder{
    visibility: visible;
  }

  .fields .no-bold{
    font-weight: 400;
  }

  .file-display-container{
    width: 80%;
  }

}
.appid-order {
  display: none;
}
.billing-month-wrapper{
  display: grid;
  grid-template-columns: 50% 50%;
}

.billing-month-wrapper .billing-field{
  display: flex;
  align-items: center;
}

.billing-month-wrapper .billing-field>label{
  margin-bottom: 0;
}
.mobile-footer{
  display: none;
}

.button-container.gray-disabled-button{
  opacity: 0.5; 
}

.button-container.gray-disabled-button button{
  cursor: not-allowed;
}

body.dimmed.dimmable{
  overflow: hidden !important;
}

.text-type-password{
  -webkit-text-security:disc;
}
.listapp-body-content .ant-input-group{
  line-height: 3 !important;
}
.listapp-body-content .ant-input{
  height: 42px;
}
.listapp-body-content .ant-input-search-button{
  height: 42.5px;
}
.section-header-right{
  float: right;
  width: 40%;
}
.ant-input-group-wrapper{
  width: 50% !important;
  float:right;
  margin-left: 30px;
}
.list-app-btn{
  float: right !important;
  margin-left: 20px !important;
  margin-right: 0px !important;
  font-size: 1.71rem;
  padding: 10px 20px;
  border: 2px solid;
  border-radius: 3px;
  outline-width: thin;
  cursor: pointer;
  transition: background 0.3s, color 0.3s;
  font-weight: 600;
  line-height: 1em;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  min-height: 1em;
  text-shadow: none!important;
  cursor: pointer;
  font-family: 'Lato', sans-serif; /* Change the font family to Lato here */
}

.list-app-btn:hover {
  color: white;
  background: orange;
}
.button-border{
  border:1px solid !important;
}
.app-actions{
  position: relative;
}
.headercheckbox{
  position: relative;
  width:4% !important;
}
.sa-check{
  margin-left: 6px;
}
td.t-data.checkbox-action{
  width:4% !important;
}
 .applist-container .bulkupdate-action{
  width:20%;
} 
.parentcheckbox input[type=checkbox]{
  width: 17px;
  height: 17px;
  position: relative;
}
.bulkupdate-action{
   margin-bottom: 15px;
}
.word-wraps .word-wrap{
  word-break: inherit;
}


@media only screen and (max-width: 1440px) {
   .listapp-body-content .ui.table thead th{
       font-size: 11.5px;
   }
}
.ui.structured.celled.table.list-table-view-viewonly tr td, 
.ui.structured.celled.table.list-table-view-viewonly tr th{
  width: 10.1% !important;
}
td.t-data.checkbox-action{
  width:4% !important;
}
.bulkupdate-action{
  width:20%;
}
.parentcheckbox input[type=checkbox]{
  width: 17px;
  height: 17px;
  position: relative;
}
.headercheckbox{
  position: relative;
  width:4% !important;
}
.sa-check{
  margin-left: 6px;
}

.input-wrapper{
  display: flex;
  justify-content: space-between;
}

.input-wrapper label{
  margin-bottom: 5px;
}

.input-wrapper input{
  width: 45%;
}

.mt-35 {
  margin-top: 35px !important;
}

@media only screen and (max-width: 1200px) {
  .bulkupdate-action{
    width:50%;
    padding:10px 20px 0px 5px
  }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .bulkupdate-action{
      padding: 15px 20px 0px 20px;
    }
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1200px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .bulkupdate-action{
      padding: 30px;
  }
  }
