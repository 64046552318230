.valuelist {
  margin-bottom: 35px;
  padding-top: 25px;
}
button.ui.button.add-dropdown {
  position: relative;
  /* align-self: center; */
  margin-bottom: 46px;
  left: 285px;
  font-family: "MONT";
}
.valuelist-button {
  /* font-family: "MONT";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  position: relative;
  width: 211px;

  top: 95px;
  left: 624px;
  color: #03b7de; */
  position: relative;
  /* align-self: center; */
  margin-bottom: 46px;
  margin-top: 10px;
  left: 285px;

  font-size: 14px;
  line-height: 17px;
  left: 90px;
  top: 0px;
}

button.ui.button.valuelist-button {
  background: #2b7fda;
  color: white;
}
.valuelist-box-line {
  margin: 10px auto 10px auto;
  width: 95%;
  height: 0px;
  border: 0.5px solid #858585;
  opacity: 0.3;}

.value-box {
  margin-bottom: 100px;
}
.full-width{
  width:80%
}
.actions-value-box {
  width: 20%;
}
.actions-value {
  display: flex;
  flex-direction: row;
  flex-basis: fit-content;
  justify-content: space-between;
  align-content: center;
}
.price-validation{
  font-size: 14px;
}