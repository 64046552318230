.downTimePage {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    justify-content: center;

}
.downTimePage .page-content {
    font-weight:bold;
    margin-top: 200px;
}
.downTimePage .page-content h1{
    font-size: 50px;
}

/* .downTimePage .page-content .errorMessage{
    margin-left: 10px;
} */