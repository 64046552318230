.fab {
  position: fixed;
  cursor: pointer;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  color: white;
  border-color: #2185d0;
  border-radius: 50%;
  text-align: center;
  line-height: 50px;
  background-color: #2185d0;
  visibility: hidden;
  transition: visibility 0.5s ease-in-out;
  z-index: 15;
}
.fab.visible {
  visibility: visible;
}
