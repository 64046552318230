.userslist-container{
    min-height: 80vh;
    overflow-x : auto;
}
.userslist-container .table-heading-container,
.listapp-container .table-heading-container {
  justify-content: space-between;
  align-items: center;
  display: flex !important;
}
.userslist-container .table-heading-container span,
.listapp-container .table-heading-container span {
  color: #2B7FDA;
  font-size: 20px;
  font-weight: 500;
  display: flex;
  align-items: center;
}
.userslist-container .table-heading-container span svg,
.listapp-container .table-heading-container span svg {
  margin-right: 8px;
  font-size: 24px;
}
.flex-spacebetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.multi-select-dropdown{
    max-width: 300px;
  }
.user-form .fields{
    justify-content: center;
}  
.edit{
    color: #03B7DE;
    margin-left: 10px;
    margin-right: 10px;
}
.delete{
    color: #616161;
    margin-left: 10px;
    margin-right: 10px;
}
.userList{
  height: 10px !important;
  width: 10px !important;
  margin: 7px;
}
 
.not-active-userList{
  height: 10px !important;
  width: 10px !important;
  margin: 7px;
  opacity: 0.4;
}
.action-items{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
}
.form-input {
    box-shadow: 0 0 0 100px #fff inset !important;
    font-size: 16px !important;
    font-weight: 400;
    letter-spacing: 1.2px !important;
    height: 42px;
    color: #878787 !important;
    border-radius: 3px !important;
    border: 1px solid #f3f3f3 !important;
    cursor: text;
  }
  .form-input-disabled {
    box-shadow: 0 0 0 100px hsl(0, 0%, 95%) inset !important;
    font-size: 16px !important;
    font-weight: 400;
    letter-spacing: 1.2px !important;
    height: 42px;
    pointer-events: none;
    color: #878787 !important;
    border-radius: 3px !important;
    border: 0px solid #f3f3f3 !important;
  }

  .fn-checkbox-wrapper {
    align-items: center;
  }

  .fn-checkbox {
    margin-top: 2px;
  }

  .user-table-actions {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

@media only screen and (max-width: 480px) {
  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) input[type=text]{
    padding-left: 1px !important;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
    height: 50px;
  }
  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) .css-6j8wv5-Input input[type=text]{
    box-shadow: none !important;
  }
  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) .css-b62m3t-container{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important;
  }
  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) .css-1s2u09g-control,.css-1pahdxg-control,.css-319lph-ValueContainer{
    min-height: 50px;
  }
  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) .field{
    margin-bottom: 12px;
  }
  /* .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) .field p{
    display: none;
  } */
  .create-edit-user .css-319lph-ValueContainer{
    padding-top: 0 8px;
  }
  .create-edit-user .css-6j8wv5-Input{
    margin: 0;
    padding: 0;
  }

  .create-edit-user .ui.form:not(.unstackable) .fields:not(.unstackable) input[role=combobox] {
    box-shadow: none !important;
  }

}
