.offices-container{
    min-height: 80vh;
}
.flex-spacebetween{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}
.multi-select-dropdown{
    max-width: 300px;
  }
.user-form .fields{
    justify-content: center;
}
.mobile-add-button {
    border: none;
    background-color: transparent;
    display: flex;
    padding: 10px;
    align-items: center;
    position: absolute;
    right: 10px;
    cursor: pointer;
}
.mobile-add-button span {
    margin:  0 0 0 5px;
    color: #2B7FDA;
    font-size: 15px;
    font-weight: 500;
}
.edit{
    color: #03B7DE;
    margin-left: 10px;
    margin-right: 10px;
}
.delete{
    color: #616161;
    margin-left: 10px;
    margin-right: 10px;
}
